import React from 'react';
import './Instruction.css';
import lilypad from '../../assets/questions/lilypad.png';
import parkingspace from '../../assets/questions/parkingspace.png';
import triangles from '../../assets/questions/triangles.png';
import {useNavigate, useParams} from 'react-router-dom';

const   Instruction = ({instruction={}, moveInstruction=()=>{}, isLastInstruction, isFirstInstruction, handleGoalChange, goal}) => {
  const navigate = useNavigate();
  const {id} = useParams();

  return (
      <div>
        <div className="fixedHeight" style={{"backgroundImage": "url('/BackgroundInstruction.png')", "borderRadius": "8px", "backgroundSize": "cover" }}>
          {/*<div className="instructionId">#{instruction.instructionId})</div>*/}
          <div className="instructionText" dangerouslySetInnerHTML={{__html: instruction.instructionText}}></div>
          {instruction.imageSrc && <img src={instruction.imageSrc === 'lilypad'
            ? lilypad
            : instruction.imageSrc === 'parkingspace'
              ? parkingspace
              : instruction.imageSrc === 'triangles'
                ? triangles
                : ''} alt="instruction image" width={'200px'}/>}
          {instruction.textareaLabel &&
          <div style={{"marginLeft": "20px", "marginTop": "20px"}}>
            <div style={{"color": "white"}}>{instruction.textareaLabel}</div>
            <textarea rows={7} cols={40} defaultValue={goal} onChange={(event) => handleGoalChange(event.target.value)}
                      className='messageBox'></textarea>
          </div>
          }
        </div>
        <div className="buttonRow">
          {isFirstInstruction
            ? <div className='buttonSpace'>&nbsp;</div>
            : <button onClick={() => moveInstruction('prev')} className="buttonBlue">&lt; Prev</button>
          }
          {isLastInstruction
            ? <div className='buttonSpace'>&nbsp;</div>
            : <button onClick={() => moveInstruction('next')} className="buttonBlue">Next &gt;</button>
          }
          {isLastInstruction &&
            <button onClick={() => navigate(`/quiz/${id}`)} className="buttonBlue">Take the Quiz</button>
          }
        </div>
      </div>
  )
};

export default Instruction;
