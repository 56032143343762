export const instructions = {
	mission: [
		// {
		// 	instructionId: 1,
		// 	instructionText: `<iframe width="560" height="315" src="https://www.youtube.com/embed/XpD46p9ktQM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`,
		// 	imageSrc: '',
		// },
		{
			instructionId: 1,
			instructionText: `<div style="color: white; text-align: left; padding: 10px; font-size: 18px;">
													<div style='font-size: 18px'>
														Welcome to Utah State University's Equine Science and Management Program in the College of Agriculture and Applied Sciences.
													</div>
													<div style="color: white; text-align: left; width: 100%; margin-top: 25px; ">
														These series of instructions outline equine safety and handling for the animal dairy and veterinary sciences department.
													</div>
													<div style="color: white; text-align: left; width: 100%; margin-top: 25px; ">
														Your safety as well as those around you depends on proper horse-handling techniques and understanding equine behavior.
													</div>
													<div style="color: white; text-align: left; width: 100%; margin-top: 25px; ">
														We are excited to work together and help build your equine knowledge.															
													</div>
												</div>`,
			imageSrc: '',
		},
		{
			instructionId: 2,
			instructionText: `<div style="color: white; text-align: left; padding: 10px; font-size: 18px;">
													<div style='font-size: 18px'>
														Terms and Definitions 
													</div>
													<div style="color: white; text-align: left; width: 100%; margin-top: 25px; ">
														Herd behavior. Horses are prey animals. They rely on the flight versus fight or agonistic behavior.
													</div>
													<div style="color: white; text-align: left; width: 100%; margin-top: 25px; ">
														Horses are also gregarious, meaning they tend to associate with others of one's kind. Gregarious originates from the Latin word 'grex', meaning herd.
													</div>
													<div style="color: white; text-align: left; width: 100%; margin-top: 25px; ">
														Being prey animals, they establish a herd that allows for them to feel safe and less vulnerable to predators. In the wild, obedience to leadership means survival.
													</div>
												</div>`,
			imageSrc: '',
		},
		{
			instructionId: 3,
			instructionText: `<div style="color: white; text-align: left; padding: 10px; font-size: 18px;">
													<div style='font-size: 18px'>
														Terms and Definitions 
													</div>
													<div style="color: white; text-align: left; width: 100%; margin-top: 25px; ">
														To obtain order and leadership, dominant behavior must be displayed to establish hierarchy within a herd. Continual threats of aggression are necessary to maintain hierarchy.
													</div>
													<div style="color: white; text-align: left; width: 100%; margin-top: 25px; ">
														Agonistic. Agonistic includes fighting, flight and other reactions associated with conflict.
													</div>
													<div style="color: white; text-align: left; width: 100%; margin-top: 25px; ">
														The horse is programmed to continually display agonistic behavior daily in all levels of interaction including the interactions displayed with humans. So where do you stand in the pecking order?
													</div>
												</div>`,
			imageSrc: '',
		},
		{
			instructionId: 4,
			instructionText: `<div style="color: white; padding: 10px; font-size: 18px;">
													<div style='font-size: 18px; text-align: left;'>
														Reactive is a classification of activities used by an animal to keep itself in harmony with its environment and adjust to sudden potentially harmful situations. Here are some forms of reactive behavior.
													</div>
													<div style="color: white; width: 100%; margin-left: 10px; margin-top: 15px; ">
														1. Simple reflex is when a horse will withdraw limb in response to local pain.
													</div>
													<div style="color: white; width: 100%; margin-left: 10px; margin-top: 15px; ">
														2. A horse communicates through body language which can be seen when they maintain visual contact and exchange signals.
													</div>
													<div style="color: white; width: 100%; margin-left: 10px; margin-top: 15px; ">
														3. Ingested behavior includes eating, drinking, food preferences, daily patterns of feeding, the mechanics of obtaining food and chewing food.
													</div>
													<div style="color: white; width: 100%; margin-left: 10px; margin-top: 15px; ">
														4. The horse is a grazing animal designed to eat constantly. They will graze for 12 to 16 hours a day.
													</div>
													<div style="color: white; width: 100%; margin-left: 10px; margin-top: 15px; ">
														5. Eliminative refers to urination and defecation. A horse urinates every four to six hours and can defecate every two to three hours. This can vary depending on the stimulus given by the environment.
													</div>
												</div>`,
			imageSrc: '',
		},
		{
			instructionId: 5,
			instructionText: `<div style="color: white; text-align: left; padding: 10px; font-size: 18px;">
													<div style='font-size: 18px'>
														Sexual behavior involves courtship, mating and maternal behavior.
													</div>
													<div style="color: white; text-align: left; width: 100%; margin-top: 25px; ">
														Stallions become more aggressive, vulgar and display the flaming reaction.
													</div>
												</div>`,
			imageSrc: '',
		},
		{
			instructionId: 6,
			instructionText: `<div style="color: white; text-align: left; padding: 10px; font-size: 18px;">
													<div style='font-size: 18px'>
														Flaming response is the term used to describe the behavior in which a horse extends its neck, raises its head and inhales as it rolls its upper lip back displaying its front teeth.
													</div>
													<div style="color: white; text-align: left; width: 100%; margin-top: 25px; ">
														Horses display the flaming response to facilitate transfer of inhaled scent molecules, otherwise known as pheromones, into the nasal organ.
													</div>
												</div>`,
			imageSrc: '',
		},
		{
			instructionId: 7,
			instructionText: `<div style="color: white; text-align: left; padding: 10px; font-size: 18px;">
													<div style='font-size: 18px'>
														Mares show signs of heat such as frequent urination and winking.
													</div>
													<div style="color: white; text-align: left; width: 100%; margin-top: 25px; ">
														Winking is when a mare will open and close the lower part of her vulva.
													</div>
												</div>`,
			imageSrc: '',
		},
		{
			instructionId: 8,
			instructionText: `<div style="color: white; text-align: left; padding: 10px; font-size: 18px;">
														<div style='font-size: 18px'>
															Epimeletic characterizes the behavior of mutual grooming, care-giving and care seeking.
														</div>
														<div style="color: white; text-align: left; width: 100%; margin-top: 25px; ">
															Examples of epimeletic activities are standing nose-to-tail to swat flies, nibbling and licking.
														</div>
													</div>`,
			imageSrc: '',
		},
		{
			instructionId: 9,
			instructionText: `<div style="color: white; padding: 10px; font-size: 18px;">
														<div style='font-size: 18px; text-align: left;'>
															Mimicry or allelomimetic are when horses copy behavior of other horses.
														</div>
														<div style="color: white; width: 100%; margin-top: 15px; ">
															This is closely related to gregarious behavior.
														</div>
														<div style="color: white; width: 100%; margin-top: 15px; ">
															Sleep and rest allow horses to restore physiological status and make metabolic recoveries in a short time.
														</div>
													</div>`,
			imageSrc: '',
		},
		{
			instructionId: 10,
			instructionText: `<div style="color: white; text-align: left; padding: 10px; font-size: 18px;">
														<div style='font-size: 18px'>
															The course of a 24-hour day consist of 19 hours of being alert, two hours in a drowsy state.
														</div>
														<div style="color: white; text-align: left; width: 100%; margin-top: 25px; ">
															They achieve a total of three hours a day of REM, brain and body sleep which can only occur when the horse is laying down.														
														</div>
														<div style="color: white; text-align: left; width: 100%; margin-top: 25px; ">
															A stay apparatus allows horses to lock their legs and relax muscles without falling over. 
														</div>
													</div>`,
			imageSrc: '',
		},
		{
			instructionId: 11,
			instructionText: `<div style="color: white; text-align: left; padding: 10px; font-size: 18px;">
														<div style='font-size: 18px'>
																Body language. Here some key factors to understanding body language on a horse.
														</div>
														<div style="color: white; text-align: left; width: 100%; margin-top: 25px; ">
															If the ears are forward, the horse is alert or paying attention. 
														</div>
														<div style="color: white; text-align: left; width: 100%; margin-top: 25px; ">
															Relaxed ears mean the horse is in a resting state. 
														</div>
														<div style="color: white; text-align: left; width: 100%; margin-top: 25px; ">
															When the ears are pinned back, the horse is uncomfortable, typically irritated or upset. 
														</div>
														<div style="color: white; text-align: left; width: 100%; margin-top: 25px; ">
															If the eyes are relaxed, the horse is calm. 
														</div>
													</div>`,
			imageSrc: '',
		},
		{
			instructionId: 12,
			instructionText: `<div style="color: white; padding: 10px; font-size: 18px;">
														<div style='font-size: 18px; text-align: left;'>
															When the horse is wide-eyed, they are alarmed.
														</div>
														<div style="color: white; width: 100%; margin-top: 15px; ">
															If the nose is relaxed, the horse is calm.
														</div>
														<div style="color: white; width: 100%; margin-top: 15px; ">
															A dilated nose means they are alert or aware.
														</div>
														<div style="color: white; width: 100%; margin-top: 15px; ">
															When the nose is wrinkled, the horse is annoyed.
														</div>
														<div style="color: white; width: 100%; margin-top: 15px; ">
															If the mouth is loose-lift, the horses relaxed.
														</div>
														<div style="color: white; width: 100%; margin-top: 15px; ">
															If he is licking his lips, he is thinking and passive.
														</div>
														<div style="color: white; width: 100%; margin-top: 15px; ">
															If the teeth are bared, the horse is aggressive.
														</div>
													</div>`,
			imageSrc: '',
		},
		{
			instructionId: 13,
			instructionText: `<div style="color: white; text-align: left; padding: 10px; font-size: 18px;">
														<div style='font-size: 18px'>
															When the horse's tail is relaxed, so are they.
														</div>
														<div style="color: white; text-align: left; width: 100%; margin-top: 25px; ">
															Tightly clamped tails mean they are uncomfortable.														
														</div>
														<div style="color: white; text-align: left; width: 100%; margin-top: 25px; ">
															If the tail is arched or flagged, they're excited and happy or scared and nervous. 
														</div>
														<div style="color: white; text-align: left; width: 100%; margin-top: 25px; ">
															When the horse is standing with one leg cocked, the horse is in a resting, calm state. 
														</div>
														<div style="color: white; text-align: left; width: 100%; margin-top: 25px; ">
															The difference between a headset, that is high versus low, is the state of their alertness. 
														</div>
														<div style="color: white; text-align: left; width: 100%; margin-top: 25px; ">
															High is very alert and low is relaxed. 
														</div>
													</div>`,
			imageSrc: '',
		},
		{
			instructionId: 14,
			instructionText: `<div style="color: white; text-align: left; padding: 10px; font-size: 18px;">
														<div style='font-size: 18px'>
															Body language is the key to understanding how to interact with the horse in its current state or mood.
														</div>
														<div style="color: white; text-align: left; width: 100%; margin-top: 25px; ">
															Let's examine human equine mimicry.														
														</div>
														<div style="color: white; text-align: left; width: 100%; margin-top: 25px; ">
															The key to establishing dominance is being able to move the horse around. 
														</div>
														<div style="color: white; text-align: left; width: 100%; margin-top: 25px; ">
															To establish dominance, a horse uses his body. 
														</div>
														<div style="color: white; text-align: left; width: 100%; margin-top: 25px; ">
															Examples would be biting, kicking and striking. 
														</div>
														<div style="color: white; text-align: left; width: 100%; margin-top: 25px; ">
															Once dominance has been established, the more aggressive actions lessen and the dominant horse then uses his body language to push others around. 
														</div>
														<div style="color: white; text-align: left; width: 100%; margin-top: 25px; ">
															This includes pinning ears, slight head swing and movement towards another horse. 
														</div>
													</div>`,
			imageSrc: '',
		},
	],
	horse: [{
		instructionId: 1,
		instructionText: `<div style="color: white; text-align: left; padding: 10px; font-size: 18px;">
													<div style='font-size: 18px'>
														Welcome I'm Doctor Kerry Rood and this is Doctor Karl Hoopes.
													</div>
													<div style="color: white; text-align: left; width: 100%; margin-top: 25px; ">
														Doctor Rood, is it crucial for students to understand the uniqueness of the horse's anatomy?
													</div>
													<div style="color: white; text-align: left; width: 100%; margin-top: 25px; ">
														Doctor Hoopes, the answer is yes.
													</div>
													<div style="color: white; text-align: left; width: 100%; margin-top: 25px; ">
														Can equine anatomy affect the behavior of a horse?															
													</div>
													<div style="color: white; text-align: left; width: 100%; margin-top: 25px; ">
														The answer again is yes.															
													</div>
												</div>`,
		imageSrc: '',
	},
		{
			instructionId: 2,
			instructionText: `<div style="color: white; text-align: left; padding: 10px; font-size: 18px;">
													<div style='font-size: 18px'>
														Understanding the horse's anatomy goes hand in hand with understanding their behavior. 
													</div>
													<div style="color: white; text-align: left; width: 100%; margin-top: 25px; ">
														Welcome to video number two.
													</div>
													<div style="color: white; text-align: left; width: 100%; margin-top: 25px; ">
														We're going to take you through the aspects of equine anatomy that are essential in safety and handling.
													</div>
													<div style="color: white; text-align: left; width: 100%; margin-top: 25px; ">
														Let us start with how a horse senses it's surroundings.
													</div>
												</div>`,
			imageSrc: '',
		},
		{
			instructionId: 3,
			instructionText: `<div style="color: white; text-align: left; padding: 10px; font-size: 18px;">
													<div style='font-size: 18px'>
														Just like humans, a horse uses it's eyes and ears to evaluate their environment. 
													</div>
													<div style="color: white; text-align: left; width: 100%; margin-top: 25px; ">
														The better you understand how they see and hear, the safer your interactions will be.
													</div>
													<div style="color: white; text-align: left; width: 100%; margin-top: 25px; ">
														A horse's field of vision is approximately 220 degrees allowing a panoramic view.
													</div>
													<div style="color: white; text-align: left; width: 100%; margin-top: 25px; ">
														Blind spots are directly in front and behind.
													</div>
												</div>`,
			imageSrc: '',
		},
		{
			instructionId: 4,
			instructionText: `<div style="color: white; padding: 10px; font-size: 18px;">
													<div style='font-size: 18px; text-align: left;'>
														Horses have both monocular vision, which is independent viewing from each eye and binocular vision, viewing from both eyes.
													</div>
													<div style="color: white; width: 100%; margin-left: 10px; margin-top: 15px; ">
														Binocular vision allows the horse to view 60-70 degrees in front of them, but allows them to perceive depth and focus on objects.
													</div>
													<div style="color: white; width: 100%; margin-left: 10px; margin-top: 15px; ">
														Because of monocular vision, a horse may travel one direction with no problems, but may view and perceive objects going the other way differently as they switch between eyes.
													</div>
													<div style="color: white; width: 100%; margin-left: 10px; margin-top: 15px; ">
														Rotating ears allow for advantageous hearing.
													</div>
													<div style="color: white; width: 100%; margin-left: 10px; margin-top: 15px; ">
														A horse's hearing is better than their eyesight.
													</div>
													<div style="color: white; width: 100%; margin-left: 10px; margin-top: 15px; ">
														They can hear higher tones than humans.
													</div>
												</div>`,
			imageSrc: '',
		},
		{
			instructionId: 5,
			instructionText: `<div style="color: white; text-align: left; padding: 10px; font-size: 18px;">
													<div style='font-size: 18px'>
														Those high tones or loud noises can cause anxiety or alarm.
													</div>
													<div style="color: white; text-align: left; width: 100%; margin-top: 25px; ">
														If a horse is startled or spooked, it's more effective to use low tones to calm the horse instead of reacting to the situation with a high tense voice.
													</div>
												</div>`,
			imageSrc: '',
		},
		{
			instructionId: 6,
			instructionText: `<div style="color: white; text-align: left; padding: 10px; font-size: 18px;">
													<div style='font-size: 18px'>
														Understanding the anatomy is important for safe interactions around the horse.
													</div>
													<div style="color: white; text-align: left; width: 100%; margin-top: 25px; ">
														Remember, use low tones, approach the horse at their shoulders, never stand in their blind spot and make sure to watch their ears to make sure they have heard you.
													</div>
													<div style='font-size: 18px'>
														You can't always predict when or how a horse will respond to their environment, but we can do our best to be in a safe position and prevent injury.
													</div>
												</div>`,
			imageSrc: '',
		},
	],
	final: [{
		instructionId: 1,
		instructionText: `<div style="color: white; text-align: left; padding: 10px; font-size: 18px;">
													<div style='font-size: 18px'>
														This is the final exam. Please continue to the questions. 
													</div>
												</div>`,
		imageSrc: '',
	},]
}