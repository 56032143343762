import React from 'react';
import './App.css';
import {Route, Routes} from 'react-router-dom';
import Home from "./app/views/Home";
import Quiz from "./app/views/Quiz";
import Instruction from "./app/views/Instruction";

function App() {
  return (
    <div className='AppAll'>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/instruction/:id" element={<Instruction />} />
        <Route path="/quiz/:id" element={<Quiz />} />
        <Route path="/quiz/:id/:newAttempt" element={<Quiz />} />
        <Route path="/quiz/review/:id/:attemptReview" element={<Quiz />} />
      </Routes>
    </div>
  );
}

export default App;
