import React, { useEffect, useState } from 'react';
import {useNavigate} from 'react-router-dom';
import AntTrail from '../../components/AntTrail';
import Question from '../../components/Question';
import './Quiz.css';
import * as data from '../../data/questions.js';
import { useMediaQuery } from 'react-responsive'
import { useParams } from 'react-router-dom';
import Logo from "../../assets/horses/EquineLogo.png";

function Quiz() {
  const navigate = useNavigate();
  const { id, attemptReview, newAttempt } = useParams();
  const [currentQuestion, setCurrentQuestion] = useState(1);
  const [dotColors, setDotColors] = useState([]);
  const [attempts, setAttempts] = useState([]);
  const [attempt, setAttempt] = useState([]);
  const [currentReview, setCurrentReview] = useState(null);
  const [currentAnswer, setCurrentAnswer] = useState();

  const isNotMobile = useMediaQuery({ query: '(min-width: 425px)' })

  useEffect(() => {
    if (typeof window !== 'undefined') {
      let quiz = window.localStorage.getItem(id);
      if (quiz) {
        let parsedJSON = parseTheJsonText(quiz);
        setAttempts(parsedJSON);
      }
    }
    if (!attemptReview && !newAttempt) setNewAttempt();
  }, [])

  useEffect(() => {
    if (newAttempt) {
      setNewAttempt();
    }
    if (attemptReview) {
      setNewReview(attemptReview);
    }
  }, [data, attemptReview, newAttempt])

  useEffect(() => {
    getAnswer();
  },[currentQuestion, currentReview])

  const getLocalData = () => {
    let quiz = window.localStorage.getItem(id);
    if (quiz) {
      return parseTheJsonText(quiz);
    }
  }

  const parseTheJsonText = (storageText) => {
    let jsonObject =  JSON.parse(storageText);
    let quizAttempts = [];
    for(let i=0; i < jsonObject.totalRecords; i++) {
      quizAttempts = quizAttempts && quizAttempts.length > 0 ? quizAttempts.concat([[jsonObject[i]]]) : [[jsonObject[i]]];
    }
    return quizAttempts;
  }

  const setNewAttempt = () => {
    // if (isFirstNewAttempt) {
    //   isFirstNewAttempt = false;
      let newAnswers = [];
      data.questions[id] && data.questions[id].length > 0 && data.questions[id].forEach((m, i) => {
          newAnswers.push({
            questionId: m.questionId,
            userAnswer: '',
            isCorrect: null
          })
        }
      )
      setAttempt(newAnswers);
    //}
  }

  const setNewReview = (number) => {
    let localAttempts = getLocalData();
    setAttempt(localAttempts && localAttempts.length > 0 && localAttempts[number-1][0]);
    setCurrentQuestion(1);
    setCurrentReview(number);
  }

  useEffect(() => {
    getDotColors();
  },[currentQuestion, attempts, currentReview]);

  const moveQuestion = (move) => {
    let questionNumber = null;
    if (move === 'prev') {
      questionNumber = currentQuestion-1;
      if (currentQuestion <= 1) setCurrentQuestion(1);
    } else if (move === 'next') {
      questionNumber = currentQuestion+1;
      if (currentQuestion >= data.questions[id].length) questionNumber = data.questions[id].length;
    } else if (!isNaN(move)) {
      questionNumber = move;
    }
    setCurrentQuestion(questionNumber);
    getAnswer(questionNumber);
  }

  const onAnswer = (questionId, answer) => {
    let newAnswers = attempt && attempt.length > 0 && attempt.map((m) => {
      if (m.questionId === questionId) {
        m.userAnswer = answer;
      }
      return m;
    })
    setAttempt(newAnswers);
    setCurrentAnswer(answer);
  }

  const getDotColors = () => {
    //1. set the currentQuestion to black
    //2. loop through the answer length.
    //    a. If the dot is answered, then blue
    //    b. If the dot is unanswered and below the greatest question answered, it is orange.
    //       otherwise it is blank
    let currentAttempt = attempts.length-1;
    let highestAnswer = 1;
    attempt && attempt.length > 0 && attempt.forEach((m, i) => {
      if (m.userAnswer && i+1 > highestAnswer) highestAnswer = i+1;
    })
    let newDotColors = [''];

    attempt && attempt.length > 0 && attempt.forEach((m, i) => {
      if (newDotColors.length === currentQuestion) {
        newDotColors[newDotColors.length] = 'black';
      } else if (currentReview) {
        if (m.userAnswer === data.questions[id][i].answer) {
          newDotColors[newDotColors.length] = 'green';
        } else {
          newDotColors[newDotColors.length] = 'red';
        }
      } else if (m.userAnswer && m.userAnswer.length > 0) {
        newDotColors[newDotColors.length] = 'blue';
      } else if (!m.userAnswer && (newDotColors.length < highestAnswer || newDotColors.length < currentQuestion)) {
        newDotColors[newDotColors.length] = 'orange';
      } else {
        newDotColors[newDotColors.length] = '';
      }
    })
    setDotColors(newDotColors);
  }

  const handleAttemptFinished = () => {
    let localAttempts = getLocalData();
    let jsonText = {}
    let recordCount = attempts.length + 1;
    jsonText.totalRecords = recordCount;
    localAttempts && localAttempts.length > 0 && localAttempts.forEach((m, index) => {
      jsonText[index] = m[0];
    });
    jsonText[recordCount - 1] = attempt;
    window.localStorage.setItem(id, JSON.stringify(jsonText)); //id is the quiz name from the home page, like "mission" or "job"
    navigate('/');
  }

  const getAnswer = (questionNumber) => {
    let answer = null;
    let questionIndex = questionNumber ? questionNumber : currentQuestion;
    attempt && attempt.length > 0 && attempt.forEach((m) => {
      if (m.questionId == data.questions[id][Number(questionIndex) - 1].questionId) {
        answer = m.userAnswer === '' || !m.userAnswer ? '' : m.userAnswer;
      }
    })
    setCurrentAnswer(answer);
  }

  return (
    <div className="AppQuiz">
      <img src={Logo} alt={'Dimension 365'} className='logo' style={{'maxWidth': '450px'}}/>
      <div className='leftPosition'>
        <div onClick={() => navigate('/')} className='linkHome'>Home</div>
        <div className={'quizTitle'}> >
          {id === 'mission'
            ? ''
            : id === 'job'
              ? ''
              : id === 'hippa'
                ? ''
                : ''
          }
        </div>
      </div>
      <div className={isNotMobile ? "row" : "column"}>
        <div>
          <div className="App-header">
            <title>Hindsight Homeschool</title>
            <h2>Hindsight Homeschool</h2>
            <div className="antTrail">
              <AntTrail current={currentQuestion} totalCount={data.questions[id].length} dotColors={dotColors}
                        moveQuestion={moveQuestion}/>
            </div>
          </div>
          <div className='question'>
            <Question question={data && data.questions[id][currentQuestion-1]}
                      moveQuestion={moveQuestion}
                      onAnswer={onAnswer}
                      currentReview={currentReview}
                      answer={currentAnswer}
                      isLastAnswer={currentQuestion === data.questions[id].length}
                      isFirstAnswer={currentQuestion === 1}
                      setAttemptFinished={() => handleAttemptFinished()}/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Quiz;
