export const questions = {
	mission: [
		{
			questionId: 1,
			questionText:  `<div style="color: white; text-align: center; padding: 10px;">
													<div style="color: white; text-align: center; width: 100%; margin-top: 25px; ">
														Proper horse handling techniques and understanding equine behavior helps ensure __________.
													</div>
												</div>`,
			imageSrc: '',
			answerType: 'SINGLE_MULT_CHOICE',
			multipleChoiceAnswers: ['your safety, as well as the safety of those around you.','that horses do not develop abnormal behavior.'],
			answer: 'your safety, as well as the safety of those around you.'
		},
		{
			questionId: 2,
			questionText:  `<div style="color: white; text-align: center; padding: 10px;">
													<div style="color: white; text-align: center; width: 100%; margin-top: 25px; ">
														Agonistic behavior is a part of the __________.
													</div>
												</div>`,
			imageSrc: '',
			answerType: 'SINGLE_MULT_CHOICE',
			multipleChoiceAnswers: ['horse keeping itself in harmony with its environment.', 'fight or flight response.', 'inherent danger.'],
			answer: 'fight or flight response.'
		},
	{
			questionId: 3,
			questionText:  `<div style="color: white; text-align: center; padding: 10px;">
														<div style="color: white; text-align: center; width: 100%; margin-top: 25px; ">
															The horse is a grazing animal and will graze ________ hours each day.
														</div>
													</div>`,
			imageSrc: '',
			answerType: 'SINGLE_MULT_CHOICE',
			multipleChoiceAnswers: ['	8-10','2-6','12-16'],
			answer: '12-16'
		},
		{
			questionId: 4,
			questionText:  `<div style="color: white; text-align: center; padding: 10px;">
													<div style="color: white; text-align: center; width: 100%; margin-top: 25px; ">
														Eliminative behavior can vary because of ____________.
													</div>
												</div>`,
			imageSrc: '',
			answerType: 'SINGLE_MULT_CHOICE',
			multipleChoiceAnswers: ['environmental stimuli.', 'gregarious tendencies.','the hierarchy of the herd.'],
			answer: 'environmental stimuli.',
		},
		{
			questionId: 5,
			questionText:  `<div style="color: white; text-align: center; padding: 10px;">
													<div style="color: white; text-align: center; width: 100%; margin-top: 25px; ">
														A simple reflex can be observed ________.
													</div>
												</div>`,
			imageSrc: '',
			answerType: 'SINGLE_MULT_CHOICE',
			multipleChoiceAnswers: ['when a horse becomes the leader of a herd.','when a horse grazes for long periods of time.','when a horse withdraws a limb in response to local pain.'],
			answer: 'when a horse withdraws a limb in response to local pain.'
		},
		{
			questionId: 6,
			questionText:  `<div style="color: white; text-align: center; padding: 10px;">
													<div style="color: white; text-align: center; width: 100%; margin-top: 25px; ">
														A horse communicates through ________.
													</div>
												</div>`,
			imageSrc: '',
			answerType: 'SINGLE_MULT_CHOICE',
			multipleChoiceAnswers: ['ingestive behavior.','its body language.','eliminative behavior.'],
			answer: 'its body language.'
		},
	{
		questionId: 7,
		questionText:  `<div style="color: white; text-align: center; padding: 10px;">
													<div style="color: white; text-align: center; width: 100%; margin-top: 25px; ">
														Sexual behavior involves __________.
													</div>
												</div>`,
		imageSrc: '',
		answerType: 'SINGLE_MULT_CHOICE',
		multipleChoiceAnswers: ['courtship, mating, and maternal behavior.','excessive rolling.','agonistic behavior.'],
		answer: 'courtship, mating, and maternal behavior.'
	},
		{
			questionId: 8,
			questionText:  `<div style="color: white; text-align: center; padding: 10px;">
													<div style="color: white; text-align: center; width: 100%; margin-top: 25px; ">
														The flehman response is used by horses to __________.
													</div>
												</div>`,
			imageSrc: '',
			answerType: 'SINGLE_MULT_CHOICE',
			multipleChoiceAnswers: ['transfer food to the stomach.','facilitate the transfer of pheromones.','attract a mate.'],
			answer: 'facilitate the transfer of pheromones.'
		},
		{
			questionId: 9,
			questionText:  `<div style="color: white; text-align: center; padding: 10px;">
													<div style="color: white; text-align: center; width: 100%; margin-top: 25px; ">
														Winking is displayed by __________.
													</div>
												</div>`,
			imageSrc: '',
			answerType: 'SINGLE_MULT_CHOICE',
			multipleChoiceAnswers: ['stallions.','mares.','geldings.'],
			answer: 'mares.'
		},
		{
		questionId: 10,
		questionText:  `<div style="color: white; text-align: center; padding: 10px;">
													<div style="color: white; text-align: center; width: 100%; margin-top: 25px; ">
														Mutual grooming, care giving and care seeking is ___________ behavior.
													</div>
												</div>`,
		imageSrc: '',
		answerType: 'SINGLE_MULT_CHOICE',
		multipleChoiceAnswers: ['gregarious','sexual','epimeletic','agonistic'],
		answer: 'epimeletic'
	},
		{
			questionId: 11,
			questionText:  `<div style="color: white; text-align: center; padding: 10px;">
													<div style="color: white; text-align: center; width: 100%; margin-top: 25px; ">
														Horses display allelomimetic behavior by ___________.
													</div>
												</div>`,
			imageSrc: '',
			answerType: 'SINGLE_MULT_CHOICE',
			multipleChoiceAnswers: ['mimicking other horses.','fight of flight responses.','displaying dominance.'],
			answer: 'mimicking other horses.'
		},
		{
			questionId: 12,
			questionText:  `<div style="color: white; text-align: center; padding: 10px;">
													<div style="color: white; text-align: center; width: 100%; margin-top: 25px; ">
														REM sleep can only be obtained by a horse when __________.
													</div>
												</div>`,
			imageSrc: '',
			answerType: 'SINGLE_MULT_CHOICE',
			multipleChoiceAnswers: ['standing up.','laying down.','in a stall.'],
			answer: 'laying down.'
		},
	{
		questionId: 13,
		questionText:  `<div style="color: white; text-align: center; padding: 10px;">
													<div style="color: white; text-align: center; width: 100%; margin-top: 25px; ">
														Pinned ears are a sign that a horse is __________.
													</div>
												</div>`,
		imageSrc: '',
		answerType: 'SINGLE_MULT_CHOICE',
		multipleChoiceAnswers: ['calm.','relaxed or tired.','happy.','upset.'],
		answer: 'upset.'
	},
		{
			questionId: 14,
			questionText:  `<div style="color: white; text-align: center; padding: 10px;">
													<div style="color: white; text-align: center; width: 100%; margin-top: 25px; ">
														Dominance is displayed through __________.
													</div>
												</div>`,
			imageSrc: '',
			answerType: 'SINGLE_MULT_CHOICE',
			multipleChoiceAnswers: ['gregarious behavior.','the flehman response.','body language.'],
			answer: 'body language.'
		},
		{
			questionId: 15,
			questionText:  `<div style="color: white; text-align: center; padding: 10px;">
													<div style="color: white; text-align: center; width: 100%; margin-top: 25px; ">
														Dilated nostrils are a sign that the horse is __________.
													</div>
												</div>`,
			imageSrc: '',
			answerType: 'SINGLE_MULT_CHOICE',
			multipleChoiceAnswers: ['relaxed.','aware.','uninterested.'],
			answer: 'aware.'
		},
		{
			questionId: 16,
			questionText:  `<div style="color: white; text-align: center; padding: 10px;">
													<div style="color: white; text-align: center; width: 100%; margin-top: 25px; ">
														True or False: Equine body language is key to how we should interact with the horse in their current state or mood.
													</div>
												</div>`,
			imageSrc: '',
			answerType: 'TRUE_FALSE',
			multipleChoiceAnswers: [''],
			answer: 'true'
		},
		{
			questionId: 17,
			questionText:  `<div style="color: white; text-align: center; padding: 10px;">
													<div style="color: white; text-align: center; width: 100%; margin-top: 25px; ">
														Once dominance is established __________.
													</div>
												</div>`,
			imageSrc: '',
			answerType: 'SINGLE_MULT_CHOICE',
			multipleChoiceAnswers: ['the aggressive body language remains the same.','the aggressive body language increases.','the aggressive body language lessens.'],
			answer: 'the aggressive body language lessens.'
		},
	],
	horse: [
		{
			questionId: 1,
			questionText:  `<div style="color: white; text-align: center; padding: 10px;">
													<div style="color: white; text-align: center; width: 100%; margin-top: 25px; ">
														Where is a horse's blind spot?.
													</div>
												</div>`,
			imageSrc: '',
			answerType: 'SINGLE_MULT_CHOICE',
			multipleChoiceAnswers: [`Where is a horse's blind spot?`,'60-70 degrees in front of it.','Directly in front, or behind, the horse.','Its monocular vision.'],
			answer: 'Directly in front, or behind, the horse.'
		},
		{
			questionId: 2,
			questionText:  `<div style="color: white; text-align: center; padding: 10px;">
													<div style="color: white; text-align: center; width: 100%; margin-top: 25px; ">
														A horse's hearing is better than its vision.  When a horse is startled, it is best to use low tones to calm the horse.
													</div>
												</div>`,
			imageSrc: '',
			answerType: 'TRUE_FALSE',
			multipleChoiceAnswers: [''],
			answer: 'true'
		},
		{
			questionId: 3,
			questionText:  `<div style="color: white; text-align: center; padding: 10px;">
														<div style="color: white; text-align: center; width: 100%; margin-top: 25px; ">
															Please fill in the blanks. A horse uses its _____ and _____ to evaluate its environment.
														</div>
													</div>`,
			imageSrc: '',
			answerType: 'SINGLE_MULT_CHOICE',
			multipleChoiceAnswers: ['Mouth and tail.','Nose and limbs.','Eyes and ears.'],
			answer: 'Eyes and ears.'
		},
	],
	final: [
		{
			questionId: 1,
			questionText:  `<div style="color: white; text-align: center; padding: 10px;">
													<div style="color: white; text-align: center; width: 100%; margin-top: 25px; ">
														Check all that apply:  You have arrived at USU’s Equine Facility and you are asked by an Instructor to go get Trixie a mare out of her stall. When approaching the stall you notice her head is turned away from you and her tail is facing toward you. Her tail is lifted and you notice her vulva “winking.” What is “winking” and what would you do before approaching her.
													</div>
												</div>`,
			imageSrc: '',
			answerType: 'MULT_ANSWER',
			multipleChoiceAnswers: [`Winking is what human’s do to tell someone they are attractive.`,`Winking is a sign of heat, and is when the lower part of a mare’s vulva opens and closes.`,`Before you approach, make sure her head is facing towards you and she can see you.`,`Before you approach her you need to sing her a song because she is in heat and a song will make her happy.`],
			answer: [`Winking is a sign of heat, and is when the lower part of a mare’s vulva opens and closes.`,`Before you approach, make sure her head is facing towards you and she can see you.`]
		},
	]
}