import React, { useEffect, useState } from 'react';
import {useNavigate} from 'react-router-dom';
import AntTrail from '../../components/AntTrail';
import InstructionComponent from '../../components/Instruction';
import './Instruction.css';
import * as data from '../../data/instructions.js';
import { useMediaQuery } from 'react-responsive'
import { useParams } from 'react-router-dom';
import Logo from "../../assets/horses/EquineLogo.png";

function Instruction() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [currentInstruction, setCurrentInstruction] = useState(1);
  const [dotColors, setDotColors] = useState('');
  const [goal, setGoal] = useState('');

  const isNotMobile = useMediaQuery({ query: '(min-width: 425px)' })

  useEffect(() => {
    if (typeof window !== 'undefined') {
      let goal = window.localStorage.getItem(`${id}Goal`);
      if (goal) setGoal(goal);
    }
  },[]);

  useEffect(() => {
    getDotColors();
  },[currentInstruction]);

  const moveInstruction = (move) => {
    let instructionNumber = null;
    if (move === 'prev') {
      instructionNumber = currentInstruction-1;
      if (currentInstruction <= 1) setCurrentInstruction(1);
    } else if (move === 'next') {
      instructionNumber = currentInstruction+1;
      if (currentInstruction >= data.instructions[id].length) instructionNumber = data.instructions[id].length;
    } else if (!isNaN(move)) {
      instructionNumber = move;
    }
    setCurrentInstruction(instructionNumber);
  }

  const getDotColors = () => {
    //1. set the currentInstruction to black
    //2. loop through the answer length.
    //    a. If the dot is answered, then blue
    //    b. If the dot is unanswered and below the greatest question answered, it is orange.
    //       otherwise it is blank
    let newDotColors = [''];
    let instructions = data.instructions[id];

    instructions && instructions.length > 0 && instructions.forEach((m, i) => {
      if (newDotColors.length === currentInstruction) {
        newDotColors[newDotColors.length] = 'black';
      } else if (i < currentInstruction) {
        newDotColors[newDotColors.length] = 'blue';
      } else {
        newDotColors[newDotColors.length] = '';
      }
    })
    setDotColors(newDotColors);
  }

  const handleGoalChange = (text) => {
    setGoal(text);
    localStorage.setItem(`${id}Goal`, text);
  }

  return (
    <div className="AppInstructions">
      <img src={Logo} alt={'Dimension 365'} className='logo' style={{'maxWidth': '450px'}}/>
      <div className='leftPosition'>
        <div onClick={() => navigate('/')} className='linkHome'>Home</div>
        <div className={'quizTitle'}> >
          {id === 'mission'
            ? ''
            : id === 'job'
              ? ''
              : id === 'hippa'
                ? ''
                : ''
          }
        </div>
      </div>
      <div className='buttonTop'>
        <button onClick={() => navigate(`/quiz/${id}`)} className="buttonQuiz">Take the Quiz</button>
      </div>
      <div className={isNotMobile ? "row" : "column"}>
        <div>
          <div className="App-header">
            <title>Dimension 365 Orientation</title>
            <h2>The Instructions</h2>
            <div className="antTrail">
              <AntTrail current={currentInstruction} totalCount={data.instructions[id].length} dotColors={dotColors}
                        moveQuestion={moveInstruction}/>
            </div>
          </div>
          <div className='instruction'>
            <InstructionComponent instruction={data && data.instructions[id][currentInstruction-1]}
                      moveInstruction={moveInstruction}
                      isLastInstruction={currentInstruction === data.instructions[id].length}
                      isFirstInstruction={currentInstruction === 1}
                      setAttemptFinished={() => navigate('/')}
                      goal={goal}
                      handleGoalChange={handleGoalChange}/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Instruction;
