import React, { useEffect, useState, useRef } from 'react';
import './Home.css';
import Logo from '../../assets/mindyLogo.png';
import * as data from '../../data/questions.js';
import Complete1 from '../../assets/complete_1.png';
import NotStarted1 from '../../assets/notstarted_1.png';
import Complete2 from '../../assets/complete_2.png';
import NotStarted2 from '../../assets/notstarted_2.png';
import Complete3 from '../../assets/complete_3.png';
import NotStarted3 from '../../assets/notstarted_3.png';
import Complete4 from '../../assets/complete_4.png';
import NotStarted4 from '../../assets/notstarted_4.png';
import Card from "../../components/Card";
import MediaQuery, {useMediaQuery} from 'react-responsive'

//To do:  Make it mobile friendly.
//Put in the time dynamically.  4:25 for that second video.
//Mae the numbers in the badges dynamic.

function Quiz() {
  const isSmallerScreen = useMediaQuery({ query: '(max-width: 1100px)' })

  const [mission, setAttemptsMission] = useState([]);
  const [job, setAttemptsJob] = useState([]);
  const [hippa, setAttemptsHippa] = useState([]);
  const [final, setAttemptsFinal] = useState([]);
  const [horse, setAttemptsHorse] = useState([]);
  const [hasCompletedMission, setHasCompletedMission] = useState(false);
  const [hasCompletedJob, setHasCompletedJob] = useState(false);
  const [hasCompletedHippa, setHasCompletedHippa] = useState(false);
  const [hasCompletedFinal, setHasCompletedFinal] = useState(false);
  const [hasCompletedHorse, setHasCompletedHorse] = useState(false);
  const [goals, setGoals] = useState(null);

  useEffect(() => {
    checkHasCompletedMission()
  }, [data, mission])

  useEffect(() => {
    checkHasCompletedJob()
  }, [data, job])

  useEffect(() => {
    checkHasCompletedHippa()
  }, [data, hippa])

  useEffect(() => {
    checkHasCompletedFinal()
  }, [data, final])

  useEffect(() => {
    checkHasCompletedHorse()
  }, [data, horse])

  const checkHasCompletedMission = () => {
    let correct = 0;
    data && data.questions && data.questions['mission'] && mission && mission.length > 0 && mission.forEach(m => {
      correct = 0
      m && m.length > 0 && m.forEach(q => {
        correct = 0
        q && q.length > 0 && q.map((a, index) => {
          if (data.questions['mission'][index].answer === a.userAnswer) correct++;
        })
      })
    })
    if (data && data.questions['mission'] && correct === data.questions['mission'].length) setHasCompletedMission(true);
  }

  const checkHasCompletedJob = () => {
    let correct = 0;
    data && data.questions && data.questions['job'] && job && job.length > 0 && job.forEach(m => {
      correct = 0
      m && m.length > 0 && m.forEach(q => {
        correct = 0
        q && q.length > 0 && q.forEach((a, index) => {
          if (data.questions['job'][index].answer === a.userAnswer) correct++;
        })
      })
    })
    if (data && data.questions['job'] && correct === data.questions['job'].length) setHasCompletedJob(true);
  }

  const checkHasCompletedHippa = () => {
    let correct = 0;
    data && data.questions && data.questions['hippa'] && hippa && hippa.length > 0 && hippa.forEach(m => {
      correct = 0
      m && m.length > 0 && m.forEach(q => {
        correct = 0
        q && q.length > 0 && q.forEach((a, index) => {
          if (data.questions['hippa'][index].answer === a.userAnswer) correct++;
        })
      })
    })
    if (data && data.questions['hippa'] && correct === data.questions['hippa'].length) setHasCompletedHippa(true);
  }

  const checkHasCompletedFinal = () => {
    let correct = 0;
    data && data.questions && data.questions['final'] && final && final.length > 0 && final.forEach(m => {
      correct = 0
      m && m.length > 0 && m.forEach(q => {
        correct = 0
        q && q.length > 0 && q.forEach((a, index) => {
          if (data.questions['final'][index].answerType === 'MULT_ANSWER') {
            //Find that the user chose the right answers and did not choose any wrong answers.
            //1. How many correct answers?
            //2. Did the user choose all the right answers?
            //3. Did the user choose any wrong answer?
            let correctAnswers = data.questions['final'][index].answer
            let userAnswers = a.userAnswer || []
            let hasAllAnswers = true
            let hasIncorrectAnswer = false
            correctAnswers && correctAnswers.length > 0 && correctAnswers.filter(c => {
              if(userAnswers.indexOf(c) === -1) hasAllAnswers = false
            })
            userAnswers && userAnswers.length > 0 && userAnswers.filter(c => {
              if(correctAnswers.indexOf(c) === -1) hasIncorrectAnswer = true
            })
            if (hasAllAnswers && !hasIncorrectAnswer) correct++
          } else {
            if (data.questions['final'][index].answer === a.userAnswer) correct++;
          }
        })
      })
    })
    if (data && data.questions['final'] && correct === data.questions['final'].length) setHasCompletedFinal(true);
  }

  const checkHasCompletedHorse = () => {
    let correct = 0;
    data && data.questions && data.questions['horse'] && horse && horse.length > 0 && horse.forEach(m => {
      correct = 0
      m && m.length > 0 && m.forEach(q => {
        correct = 0
        q && q.length > 0 && q.forEach((a, index) => {
          if (data.questions['horse'][index].answer === a.userAnswer) correct++;
        })
      })
    })
    if (data && data.questions['horse'] && correct === data.questions['horse'].length) setHasCompletedHorse(true);
  }

  let todayDate = new Date();
  return (
    <div className="App">
      <div>
        <img src={Logo} alt={'Hindsight Homeschool'} className='logo' style={{'maxWidth': isSmallerScreen ? '350px' : '650px', paddingLeft: '25px'}}/>
        <div className='background'>
          <div className='welcome'>Welcome! We are glad you are here.</div>
          {/*<div>*/}
          {/*  <div className='orientations'>There are two orientations to complete and a final test:</div>*/}
          {/*  <div className='tryAgain'>You must complete each quiz 100%.</div>*/}
          {/*  <div className='tryAgain'>(Don't worry. You can try more than once.)</div>*/}
          {/*</div>*/}
          <div className='quizList'>
            <Card quizName={'mission'} completeImage={Complete1} notStartedImage={NotStarted1}
                  videoSource={'https://www.youtube.com/embed/Q5gF18h31Og'}
                  title={'Every Child is Unique'}
                  description={`Our best days of homeschool were un-structured and flexible. Some days we could read for hours and hours. Other days, we only had a few minutes of sitting time in us. Whenever we (I) felt like "school time" needed to be structured or look a certain way, we struggled. Looking back, I often wonder why it took me so long to learn.  The paradigm had to be shifted one aching piece at a time, of course at the expense of my children.`}
                  hasCompleted={hasCompletedMission}
                  videoDuration={'2 minutes 18 seconds'}>
            </Card>
            <Card quizName={'mission'} completeImage={Complete2} notStartedImage={NotStarted2}
                  videoSource={'https://www.youtube.com/embed/00yT4IWIx9Q'}
                  title={'In the Woods with a Machete'}
                  description={`What I learned from my children about homeschool. My oldest child, my mountain man, was MY mentor in all of this. Each of my children taught me so many valuable lessons in education and my hope is to share them with the homeschoolers of today.`}
                  hasCompleted={hasCompletedMission}
                  videoDuration={'4 minutes 25 seconds'}>
            </Card>
            <Card quizName={'mission'} completeImage={Complete3} notStartedImage={NotStarted3}
                  videoSource={'https://youtube.com/embed/Sed8FaPuKgk'}
                  title={'Leadership Education'}
                  description={`Four Phases:  Core Phase, Love of Learning, Scholar Phase, and Depth Phase`}
                  hasCompleted={hasCompletedMission}
                  videoDuration={'7 minutes 49 seconds'}>
            </Card>
            <Card quizName={'mission'} completeImage={Complete4} notStartedImage={NotStarted4}
                  videoSource={'https://youtube.com/embed/7MrxvHn3Wz4'}
                  title={'Guide with Fun to Intrique'}
                  description={`Four Principles:  1. A rich learning environment. 2. Freedom to try (and fail). 3. Learn at their best pace. 4. Children can't NOT learn! `}
                  hasCompleted={hasCompletedMission}
                  videoDuration={'5 minutes 9 seconds'}>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Quiz;

