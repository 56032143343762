import React from 'react';
import './Question.css';
import lilypad from '../../assets/questions/lilypad.png';
import parkingspace from '../../assets/questions/parkingspace.png';
import triangles from '../../assets/questions/triangles.png';
import greenArrow from '../../assets/GreenArrow.png';
import wrongPointer from '../../assets/WrongPointer.png';
import {useNavigate} from 'react-router-dom';
import Checkbox from '../Checkbox'

const   Question = ({question={}, answer='', onAnswer=()=>{}, moveQuestion=()=>{}, isLastAnswer, isFirstAnswer, setAttemptFinished=()=>{}, currentReview}) => {
  const navigate = useNavigate();

  const checkboxChoice = (questionId, newAnswer) => {
    let newAnswerArray = answer && answer.length > 0 ? [...answer] : []
    let hasAnswer = answer && answer.length > 0 && answer.filter(f => f === newAnswer)[0]
    if (hasAnswer) {
      newAnswerArray = newAnswerArray.filter(f => f === newAnswer)
    } else {
      newAnswerArray.push(newAnswer)
    }
    onAnswer && onAnswer(questionId, newAnswerArray)
  }

  return (
      <div style={{'color': 'white'}}>
        <div className="fixedHeight" style={{"backgroundImage": "url('/BackgroundQuestion.png')", "borderRadius": "8px", "backgroundSize": "cover"}}>
          <div className="questionId">#{question.questionId})</div>
          <div className="questionText" style={{fontSize: '20px'}} dangerouslySetInnerHTML={{__html: question.questionText}}></div>
          {question.imageSrc && <img src={question.imageSrc === 'lilypad'
                                            ? lilypad
                                            : question.imageSrc === 'parkingspace'
                                                ? parkingspace
                                                : question.imageSrc === 'triangles'
                                                  ? triangles
                                                  : ''} alt="question image" width={'200px'}/>}
          <div className="space">
            {currentReview && (answer === '' || (!answer && answer !== 'false')) &&
              <div className="noAnswer">Wrong: No Answer</div>
            }
            {question.answerType === 'EDIT_CONTROL'
              ? !currentReview
                ?  <div className="answerText">
                     <input key={question.questionId} defaultValue={answer ? answer : ''} onKeyUp={(event) => onAnswer(question.questionId, event.target.value)} size={10}/>
                   </div>
                : question.answer === answer || (!answer || answer === '')
                  ? <div className="rowImage">
                      <img src={greenArrow} alt={'correct'} className="reviewImage"/>{question.answer}
                    </div>
                  : <div>
                      <div className="rowImage">
                        <img src={greenArrow} alt={'correct'} className="reviewImage"/>{question.answer}
                      </div>
                      <div className="rowImage">
                        <img src={wrongPointer} alt={'wrong'} className="reviewImage"/>{answer}
                      </div>
                    </div>
              : ''
            }
            {question.answerType === 'TRUE_FALSE'
              ? !currentReview
                ?
                  <div className='row fontSize'>
                    <div className='row'>
                      <input type={'radio'} name={'answer'} value={'true'} onChange={(event) => onAnswer(question.questionId, event.target.value)} checked={answer === 'true'}/>
                      <div className='link' onClick={() => onAnswer(question.questionId, 'true')}>True</div>
                    </div>
                    <div className='row'>
                      <input type={'radio'} name={'answer'} value={'false'} onChange={(event) => onAnswer(question.questionId, event.target.value)} checked={answer === 'false'} className="falseSpace"/>
                      <div className='link' onClick={() => onAnswer(question.questionId, 'false')}>False</div>
                    </div>
                  </div>
                :
                  <div className="fontSize">
                    <div className="row">
                      {question.answer === 'true'
                        ? <img src={greenArrow} alt={'correct'} className="reviewImage"/>
                        : answer === 'true'
                          ? <img src={wrongPointer} alt={'wrong'} className="reviewImage"/>
                          : <div className='answerSpace'>&nbsp;</div>
                      }
                      <div className="answer">True</div>
                    </div>
                    <div className="row">
                      {question.answer === 'false'
                        ? <img src={greenArrow} alt={'correct'} className="reviewImage"/>
                        : answer === 'false'
                          ? <img src={wrongPointer} alt={'wrong'} className="reviewImage"/>
                          : <div className='answerSpace'>&nbsp;</div>
                      }
                      {/*<div className={question.answer !== 'false' && answer !== 'false' ? "answerSpace" : "answer"} >False</div>*/}
                      <div className="answer">False</div>
                    </div>
                  </div>
              : ''
            }
            {question.answerType === 'SINGLE_MULT_CHOICE'
              ? !currentReview
                ? <div className="answerText">
                    Multiple choice:
                    {question.multipleChoiceAnswers && question.multipleChoiceAnswers.length > 0 && question.multipleChoiceAnswers.map((m, i) =>
                      <div key={i} className='rowAnswer'>
                        <input type={'radio'} name={'mult'} value={m} onChange={(event) => onAnswer(question.questionId, event.target.value)} checked={answer === m}/>
                        <div className='link' onClick={() => onAnswer(question.questionId, m)}>{m}</div>
                      </div>
                    )}
                  </div>
                : <div className="answerText">
                    Multiple choice:
                    {question.multipleChoiceAnswers && question.multipleChoiceAnswers.length > 0 && question.multipleChoiceAnswers.map((m, i) =>
                      <div key={i}>
                        {question.answer === m
                          ? <div className="rowImage">
                              <img src={greenArrow} alt={'correct'} className="reviewImage"/>{m}
                            </div>
                          : answer === m
                            ? <div className="rowImage">
                                <img src={wrongPointer} alt={'wrong'} className="reviewImage"/>{m}
                              </div>
                            : <div className='answerSpace'>{m}</div>
                        }
                      </div>
                    )}
                  </div>
              : ''
            }
            {question.answerType === 'MULT_ANSWER'
              ? !currentReview
                ? <div className="answerText">
                  Multiple choice:
                  {question.multipleChoiceAnswers && question.multipleChoiceAnswers.length > 0 && question.multipleChoiceAnswers.map((m, i) => {
                    let isChecked = answer && answer.length > 0 && answer.filter(f => f === m)[0]
                    return (
                    <div key={i} className='rowAnswer'>
                      <Checkbox label={m}
                                checked={isChecked || false}
                                defaultValue={false}
                                onClick={() => checkboxChoice(question.questionId, m)}
                                labelClass='checkboxLabel'/>
                    </div>)
                  })}
                </div>
                : <div className="answerText">
                  Multiple choice:
                  {question.multipleChoiceAnswers && question.multipleChoiceAnswers.length > 0 && question.multipleChoiceAnswers.map((m, i) =>
                    <div key={i}>
                      {question.answer.indexOf(m) > -1
                        ? <div className="rowImage">
                          <img src={greenArrow} alt={'correct'} className="reviewImage"/>{m}
                        </div>
                        : answer === m
                          ? <div className="rowImage">
                            <img src={wrongPointer} alt={'wrong'} className="reviewImage"/>{m}
                          </div>
                          : <div className='answerSpace'>{m}</div>
                      }
                    </div>
                  )}
                </div>
              : ''
            }
          </div>
        </div>
        <div className="buttonRow">
          {isFirstAnswer
            ? <div className='buttonSpace'>&nbsp;</div>
            : <button onClick={() => moveQuestion('prev')} className="buttonBlue">&lt; Prev</button>
          }
          {isLastAnswer
            ? <div className='buttonSpace'>&nbsp;</div>
            : <button onClick={() => moveQuestion('next')} className="buttonBlue">Next &gt;</button>
          }
          {isLastAnswer
            ? currentReview
              ? <button onClick={() => navigate('/')} className="buttonBlue">Home</button>
              : <button onClick={setAttemptFinished} className="buttonBlue">Finish</button>
            : ''
          }
        </div>
        {currentReview && question.explanation &&
          <div className='explanation'>
            <strong>Explanation:&nbsp;&nbsp;</strong>
            <div dangerouslySetInnerHTML={{__html: question.explanation}}></div>
          </div>
        }
      </div>
  )
};

export default Question;
