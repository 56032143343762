import React from 'react';
import './Card.css';
import {useNavigate} from 'react-router-dom';
import Icon from "../Icon";
import MediaQuery, {useMediaQuery} from 'react-responsive'

const Card = ({sectionName, description, completeImage, notStartedImage, title, hasCompleted, videoSource, image, disabled, hasClickedIn, videoDuration}) => {
	const navigate = useNavigate();
	const isSmallerScreen = useMediaQuery({ query: '(max-width: 1100px)' })

	return (
		<div className='card'>
			<div className={isSmallerScreen ? 'columnSpace' : 'rowSpace'}>
				<div className='iconSpace'>
					<img src={hasCompleted ? completeImage : notStartedImage} alt='first'
					     className={hasCompleted ? 'badge' : 'badgeSmaller'}/>
					<div
						className='notstarted'>{hasCompleted ? 'COMPLETE' : hasClickedIn && hasClickedIn.length > 0 ? 'STARTED' : 'NOT STARTED'}</div>
				</div>
				<MediaQuery maxWidth={1100}>
					<div className='sectionName' onClick={() => navigate(`/instruction/${sectionName}`)}>{title}</div>
				</MediaQuery>
				{videoSource &&
					<div>
						<iframe width="300" height="150" src={videoSource}
						        title="YouTube video player" frameBorder="0"
						        className="youtubeFrame"
						        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
						        allowFullScreen></iframe>
						<div className='timeLength'>{videoDuration}</div>
					</div>
				}

				<div>
					<MediaQuery minWidth={1101}>
						<div className='sectionName' onClick={() => navigate(`/instruction/${sectionName}`)}>{title}</div>
					</MediaQuery>
					<div className={isSmallerScreen ? 'descriptionNarrow' : 'description'}>{description}</div>
					{/*<div className={isSmallerScreen ? 'descriptionNarrow' : 'description'} dangerouslySetInnerHTML={{description}}></div>*/}
					<div>
						<div className='linkText' onClick={() => navigate(`instruction/${sectionName}`)}>
							<Icon pathName={'magnifier'} premium={true} className='iconButton' fillColor={'black'}/>
							<div className='buttonText'>'Read more! ... </div>
						</div>
					</div>
				</div>
				{/*<img src={image} height={45}/>*/}
			</div>
		</div>
	)
};

export default Card;
